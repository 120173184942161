import Api from '@/api/api';
import { DateHelper as dates } from '@tcgplayer/martech-components';
import delve from 'dlv';

const processResponse = (response) => {
  const result = { articles: [] };
  const articles = delve(response, 'data.result', { author: {}, articles: [] }) || [];

  for (let i = 0; i < articles.length; i++) {
    if (articles[i].date != null) {
      articles[i].date = dates.format(articles[i].date);
    }

    if (articles[i].featured === true) {
      result.featured = articles[i];
    }
  }

  result.articles = articles;
  const { count, offset, total } = delve(response, 'data', { count: 0, offset: 0, total: 0 });
  result.morePages = delve(response, 'data.meta.hasNextPage', (count + offset) < total);
  result.total = total;

  return result;
};

export default {
  // WARNING: RETURNED FUNCTION MUST FOLLOW SIGNATURE:
  // ({rows, offset, featured}) => Response{}
  Loader(vertical, contentType, format) {
    return ({ rows, offset, featured }) => Api.getVerticalPage({
      verticals: vertical,
      contentType,
      formats: format,
      offset,
      rows,
      featured,
    }).then(processResponse);
  },

  // WARNING: RETURNED FUNCTION MUST FOLLOW SIGNATURE:
  // (rows, offset, featured) => Response{}
  DeckFeedLoader({
    game, format, ownerNames, ownerName, authorID, eventNames, latest, isAdmin, testDecks, rankOrder, sort, order,
  }) {
    if (eventNames) {
      eventNames = encodeURIComponent(eventNames);
    }

    return ({
      rows, offset, overrideSort, overrideOrder,
    }) => Api.getDeckFeed({
      rows, offset, game, format, ownerNames, ownerName, authorID, eventNames, latest, isAdmin, testDecks, sort: overrideSort || sort, order: overrideOrder || order,
    })
      .then(processResponse)
      .then((res) => {
        if (rankOrder && res.articles.length) {
          // Find the first item with a rank
          let position = 0;
          for (let i = 0; i < res.articles.length; i++) {
            if (delve(res, `articles.${i}.deckData.eventRank`)) {
              position = i;
              break;
            }
          }

          // Concat the slice of the ones with rank followed by the ones without if we found something
          if (position > 0) {
            res.articles = [].concat(res.articles.slice(position), res.articles.slice(0, position));
          }
        }

        return res;
      })
      .catch((err) => {
        if (err.message.indexOf('404') > -1) {
          return {
            articles: [],
            total: 0,
            morePages: false,
          };
        }

        throw err;
      });
  },

  AffiliateDeckFeedLoader({
    authorID, latest, sort, order,
  }) {
    return ({
      rows, offset,
    }) => Api.getAffiliateDeckFeed({
      authorID, rows, offset, latest, sort, order,
    })
      .then(processResponse)
      .then(res => res)
      .catch((err) => {
        if (err.message.indexOf('404') > -1) {
          return {
            articles: [],
            total: 0,
            morePages: false,
          };
        }

        throw err;
      });
  },

  // WARNING: RETURNED FUNCTION MUST FOLLOW SIGNATURE:
  // (rows, offset, featured) => Response{}
  EventsFeedLoader({
    game, format, sort, order,
  }) {
    return ({
      rows, offset, overrideSort, overrideOrder,
    }) => Api.getEventsFeed({
      rows, offset, game, format, sort: overrideSort || sort, order: overrideOrder || order,
    })
      .then(processResponse);
  },
};
