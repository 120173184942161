import axios from 'axios';
import delve from 'dlv';
import { PageRequestID, Auth } from '@tcgplayer/martech-components';

const rax = require('retry-axios');

const source = process.env.VUE_APP_TCGPLAYER_INFINITE_API_URL.includes('cfb') ? 'cfb-infinite-content' : 'infinite-content';

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_TCGPLAYER_INFINITE_API_URL,
  withCredentials: true,
  headers: {
    Accept: '/',
    'Cache-Control': 'no-cache',
  },
});

apiClient.interceptors.request.use(Auth.authInterceptor());
apiClient.interceptors.request.use(PageRequestID.interceptor);

const query = (args, sep) => {
  if (typeof args !== 'object' || !args) {
    return '';
  }
  if (!sep) {
    sep = ',';
  }

  const queryValues = [ `source=${source}` ];
  Object.keys(args).forEach((key) => {
    let value = args[key];
    if (typeof value !== 'undefined' && (value || value === false)) {
      if (Array.isArray(value)) {
        value = value.join(sep);
      }
      queryValues.push(`${key}=${value}`);
    }
  });

  return queryValues.join('&');
};

const cache = {};
let resultsUsed = {};

rax.attach(apiClient);

function fillOutItems(uniqueItems, result, count, uniqueResultsKey) {
  for (let i = 0; i < result.length; i++) {
    let match = false;
    for (let j = 0; j < uniqueItems.length; j++) {
      if (result[i][uniqueResultsKey] === uniqueItems[j][uniqueResultsKey]) {
        match = true;
        break;
      }
    }

    if (!match) {
      uniqueItems.push(result[i]);
      if (uniqueItems.length >= count) break;
    }
  }

  return uniqueItems;
}

function getUniqueItems(res, count, uniqueResultsKey, requiredVerticals) {
  if (!resultsUsed[uniqueResultsKey]) resultsUsed[uniqueResultsKey] = {};

  let uniqueItems = (res?.data?.result || []).filter(item => !resultsUsed[uniqueResultsKey][item[uniqueResultsKey]]);

  // If we have required verticals we need to deal with the fact that we fetched double the requested row count
  // and so will have a bunch of extras that aren't necessarily wanted...we can't just blindly slice off the end
  // because the unwanted ones may be newer
  if (requiredVerticals) {
    const rv = structuredClone(requiredVerticals);
    const required = [];
    const maybe = [];
    for (let i = 0; i < uniqueItems.length; i++) {
      const vertical = uniqueItems[i].vertical.toLowerCase();
      if (rv[vertical]) {
        required.push(uniqueItems[i]);
        rv[vertical]--;
      } else {
        maybe.push(uniqueItems[i]);
      }
    }

    uniqueItems = required;

    const remainder = Math.min(count - required.length, maybe.length);
    if (remainder > 0) {
      for (let i = 0; i < remainder; i++) {
        for (let j = 0; j < uniqueItems.length; j++) {
          if (new Date(maybe[i].dateTime) > new Date(uniqueItems[j].dateTime)) {
            uniqueItems.splice(j, 0, maybe[i]);
            break;
          }
        }
      }
    }
  }

  uniqueItems = uniqueItems.slice(0, count);

  for (let i = 0; i < uniqueItems.length; i++) {
    resultsUsed[uniqueResultsKey][uniqueItems[i][uniqueResultsKey]] = true;
  }

  // Fill out the items with non-unique if we don't have enough unique so we get the proper count
  if (uniqueItems.length < count) {
    uniqueItems = fillOutItems(uniqueItems, res.data.result, count, uniqueResultsKey);
  }

  return uniqueItems;
}

export default {
  clearUsedResults() {
    resultsUsed = {};
  },
  async request(url, queryString, uniqueResultsKey, cachePromise, requiredVerticals) {
    let count;
    queryString = queryString || {};
    queryString.params = queryString.params || {};
    queryString.separator = queryString.separator || ',';
    queryString.raw = queryString.raw || '';

    if (uniqueResultsKey) {
      count = queryString.params.rows;
      queryString.params.rows *= 2;
    }
    url += `?${query(queryString.params, queryString.separator)}${queryString.raw}`;

    if (cache[url]) return cache[url];

    const promise = apiClient.get(url);
    if (cachePromise) {
      cache[url] = promise;
    }
    await promise.then((res) => {
      if (uniqueResultsKey && Array.isArray(delve(res, 'data.result'))) {
        res.data.result = getUniqueItems(res, count, uniqueResultsKey, requiredVerticals);
        res.data.count = res.data.result.length;
      }
      cache[url] = res;
    });

    return cache[url];
  },
  async autocomplete(term, params) {
    term = term.slice(0, 25);
    const url = `/content/autocomplete/${encodeURIComponent(term)}/`;
    const qs = { params };
    return this.request(url, qs);
  },
  async searchContent(searchParams) {
    const url = '/content/articles/search/';
    const qs = { params: searchParams };
    return this.request(url, qs);
  },
  async getArticles({
    commanderColors, contentType, formats, omitFormats, editorsChoice, exclude, featured,
    highlighted, internalTags, requiredVerticals, series, tags, verticals,
    rows, offset, uniqueResultsField,
  }) {
    let rvString;
    if (requiredVerticals !== null && typeof requiredVerticals === 'object') {
      rvString = Object.keys(requiredVerticals).reduce((acc, cv) => {
        if (acc !== '') {
          acc += '|';
        }

        // If we are doubling the number of results we should double the minimum required for each requiredVerticals entry
        const multiplyBy = uniqueResultsField ? 2 : 1;

        acc += `${cv}:${requiredVerticals[cv] * multiplyBy}`;
        return acc;
      }, '');
    }
    const url = '/c/articles/';
    const qs = {
      params: {
        commanderColors,
        contentType,
        formats,
        omitFormats,
        editorsChoice,
        exclude,
        featured,
        highlighted,
        internalTags,
        requiredVerticals: rvString,
        series,
        tags,
        verticals,
        rows,
        offset,
      },
      separator: '|',
    };
    return this.request(url, qs, uniqueResultsField, false, requiredVerticals)
      .catch(() => ({}));
  },
  async getArticlesBySearchTerm({
    game, contentType, format, search, rows, offset, affiliate,
  }) {
    search = encodeURIComponent(search);

    const url = '/content/articles/search/';
    const qs = {
      params: {
        contentType, game, format, search, rows, offset, affiliate,
      },
      separator: '|',
    };
    return this.request(url, qs);
  },
  async getDecksBySearch({
    game, rows, offset, format, colors, tournamentOnly, affiliate,
  }) {
    const url = '/content/search/';

    const qs = {
      params: {
        contentType: 'deck', sort: 'created', order: 'desc', game, format, colors, rows, offset, tournamentOnly, affiliate,
      },
    };

    if (tournamentOnly) {
      qs.params.eventNames = '{exists}';
    }

    return this.request(url, qs);
  },
  async getVerticalPage({
    verticals, contentType, formats, offset, rows, featured, exclude, highlighted, editorsChoice, uniqueResultsField, requiredVerticals,
  }) {
    return this.getArticles({
      verticals, contentType, formats, offset, rows, featured, exclude, highlighted, editorsChoice, uniqueResultsField, requiredVerticals,
    });
  },
  async getFeaturedDecks(game, offset, rows) {
    const url = '/content/decks/featured/';
    const qs = {
      params: {
        game, offset, rows,
      },
    };
    return this.request(url, qs);
  },
  async getVerticalHero(domain, vertical, tag, offset, rows) {
    const url = `/c/hero/${domain}/`;
    const qs = {
      params: {
        vertical, tag, offset, rows,
      },
    };
    return this.request(url, qs);
  },
  async getSeriesArticles(series, contentType, exclude, offset, rows) {
    return this.getArticles({
      series, contentType, exclude, offset, rows,
    });
  },
  async getVideos(params) {
    const qs = { params };
    return this.request('/c/videos/', qs);
  },
  async getLiveStreams() {
    return this.request('/c/stream/live/');
  },
  async getArticle(articleId) {
    return this.request(`/content/article/${articleId}/`);
  },
  async getArticleMetadata(articleId) {
    return this.request(`/c/article/metadata/${articleId}/`);
  },
  async getAuthor({ authorId, ...params }) {
    const qs = { params };
    return this.request(`/content/author/${authorId}/`, qs);
  },
  async getAuthorMeta(authorID) {
    return this.request(`/c/author/${authorID}/`);
  },
  async getDeckFeed({
    rows, offset, game, format, ownerNames, ownerName, authorID, eventNames, latest, isAdmin, testDecks, sort, order,
  }) {
    const url = `/content/decks/${game}`;
    const qs = {
      params: {
        offset, rows, format, ownerNames, ownerName, authorID, eventNames, latest, isAdmin, td: testDecks, sort, order,
      },
    };
    return this.request(url, qs);
  },
  async getAffiliateDeckFeed({
    rows, offset, authorID, latest, sort, order,
  }) {
    const url = '/decks/';
    const qs = {
      params: {
        authorID, offset, rows, latest, sort, order,
      },
    };
    return this.request(url, qs);
  },
  async getDeck(deckId, game, isExternalId) {
    const url = `/deck/${game}/${deckId}/`;
    const qs = {
      params: { subDecks: true, cards: true, stats: true },
    };
    if (isExternalId) {
      qs.params.external = true;
    }
    return this.request(url, qs);
  },
  async getEventsFeed({
    rows, offset, game, format, sort, order, games,
  }) {
    const gamePath = game ? `/${game}` : '';
    const url = `/content/events${gamePath}/`;
    const qs = {
      params: {
        offset, rows, format, sort, order, games,
      },
    };
    return this.request(url, qs);
  },
  async getEvent(eventId) {
    return this.request(`/content/event/${eventId}/`);
  },
  async getCard(id, game, variantID, variantSet, excludeVariants) {
    if (typeof id === 'number') {
      id = id.toString();
    }

    const url = `/card/${game}/${encodeURIComponent(id)}/`;
    const qs = { params: { variantID, variantSet, excludeVariants } };
    return this.request(url, qs);
  },
  async getPricePoints(id) {
    return this.request(`/price/price-points/${id}/`);
  },
  async getPromos({
    tags,
  }) {
    let queryString = '';
    const tagSets = [];
    const values = Object.values(tags);
    for (let i = 0; i < values.length; i++) {
      tagSets.push(`tags=${values[i].join('|')}`);
    }
    queryString = tagSets.join('&');
    const url = '/c/promos/';
    const qs = {
      raw: `&${queryString}`,
    };
    return this.request(url, qs);
  },
  async getTags({
    domains, classifications, labels,
  }) {
    const url = '/c/tags/';
    const qs = {
      params: { domains, classifications, labels },
      separator: '|',
    };
    return this.request(url, qs);
  },
  async getSeries({
    domains,
  }) {
    const url = '/c/series/';
    const qs = {
      params: { domains },
    };
    return this.request(url, qs);
  },
  async getMarketingNewsletterCopy(newsletterId) {
    return this.request(`/c/newsletter/${newsletterId}`, null, null, true);
  },
  async getPromoCode(promoCode) {
    return apiClient.get(`/promo/${promoCode}/?source=${source}`);
  },
  async getSignupData() {
    return apiClient.get(`/signup/?source=${source}`);
  },
  async getManageData(billingHistory) {
    return apiClient.get(`/manage/?withBillingHistory=${billingHistory}&source=${source}`);
  },
  async getTransactions() {
    return apiClient.get('/subscription/webhook/log');
  },
  async createSubscription(paymentToken, promoCode) {
    if (promoCode) {
      return apiClient.put(`/subscribe/${paymentToken}/${promoCode}/?source=${source}`);
    }

    return apiClient.put(`/subscribe/${paymentToken}/?source=${source}`);
  },
  async updatePaymentMethod(paymentToken) {
    return apiClient.put(`/subscription/payment/${paymentToken}/?source=${source}`);
  },
  async cancelSubscription() {
    return apiClient.delete(`/cancel/?source=${source}`);
  },
  async subscribeUserToNewsletters(email, dripTag, euConsent, euConsentMessage) {
    const data = { tag: dripTag };

    // Only add if we have presented the user a message/checkbox to grant/deny
    if (euConsentMessage) {
      data.euConsent = euConsent ? 'granted' : 'denied';
      data.euConsentMessage = euConsentMessage;
    }

    return apiClient.patch(`/user/${email}/`, data);
  },
  async getProductByID(id) {
    return this.request(`/product/${id}`);
  },
  async getTaggedContent({
    verticals, tags, internalTags, commanderColors, series, contentType, formats, omitFormats, offset, rows, featured, exclude, uniqueResultsField,
  }) {
    return this.getArticles({
      verticals, tags, internalTags, commanderColors, series, contentType, formats, omitFormats, offset, rows, featured, exclude, uniqueResultsField,
    });
  },
  async getTagMetadata({ tagName }) {
    return this.request(`/c/tag/metadata/${tagName}/`);
  },
  async getSeriesMetadata({ seriesName }) {
    return this.request(`/c/series/metadata/${seriesName}/`);
  },
  async getTrendingContent({
    bucketSize, game, offset, rows, exclude, uniqueResultsField, format, formats,
  }) {
    const url = '/content/articles/trending/';
    const qs = {
      params: {
        bucketSize, game, offset, rows, exclude,
      },
    };
    if (format) qs.params.format = format;
    if (formats) qs.params.formats = formats;
    return this.request(url, qs, uniqueResultsField);
  },
  async getTrendingDecks(
    {
      bucketSize, game, offset, rows, format, exclude, maxAge, eventName,
    }
  ) {
    const url = '/content/decks/trending/';
    const qs = {
      params: {
        bucketSize, game, offset, rows, format, exclude, maxAge, eventName,
      },
    };
    return this.request(url, qs);
  },
  async getTrendingEvents(
    {
      bucketSize, game, offset, rows, format, exclude,
    }
  ) {
    const url = '/content/events/trending/';
    const qs = {
      params: {
        bucketSize, game, offset, rows, format, exclude,
      },
    };
    return this.request(url, qs);
  },
  async getSeriesVideos(series, season, rows, offset) {
    const url = '/c/seriesvideo/';
    const qs = {
      params: {
        series, season, rows, offset,
      },
    };
    return this.request(url, qs);
  },
  async youtubeRssFeed(channelID) {
    const url = '/c/youtube/rss/';

    return apiClient.get(`${url}${channelID}`);
  },
  async getAlerts() {
    return this.request('/c/alert/infinite');
  },
  async getVerticals() {
    return this.request('/c/verticals/', null, null, true);
  },
  async promoImpression(id, nonce) {
    const client = axios.create({
      baseURL: process.env.VUE_APP_TCGPLAYER_INFINITE_API_URL,
      withCredentials: true,
      headers: {
        Accept: '/',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${nonce}`,
      },
    });

    client.interceptors.request.use(PageRequestID.interceptor);
    client.patch(`/c/promo/impression/${id}/?source=${source}`);
  },
  getSets(game) {
    return apiClient.get(`/dashboard/sets/${game}/?source=infinite-dashboard`);
  },
  async createAffiliateDeck({ game, deck, id }) {
    return apiClient.post(`/${game}/deck/affiliate/`, deck);
  },
  async getPreviewDeck({ game, deck }) {
    return apiClient.post(`/${game}/deck/affiliate/preview/`, deck);
  },
  async getAffiliateDeck({ game, id, fullResponse }) {
    let qs = {};
    if (fullResponse) {
      qs = {
        params: { subDecks: true, cards: true, stats: true },
      };
    }

    return apiClient.get(`/${game}/deck/affiliate/${id}`, qs);
  },
  // Matching getDecks params
  async getAffiliateDecks(game, search, offset, rows, sortBy, sortDir) {
    const qs = {
      params: {
        rows, offset, noCache: true,
      },
    };

    if (sortBy) {
      qs.sortBy = sortBy;
    } else {
      qs.latest = true;
    }
    if (sortDir) qs.sortDir = sortDir;

    return apiClient.get('/decks/affiliate/', qs);
  },
};
