<template>
  <div class="grid-item" :data-testid="componentID()">
    <grid-article-card
      v-if="type === 'article' || type === 'video' || type === 'podcast'"
      :author-url="{ path: `${articleData.authorURL}` }"
      :article-card-link="{ path: `${articleData.canonicalURL}` }"
      :article-data="articleData"
      :show-vertical-link="showVerticalLink"
      :show-overlay="showOverlay"
      :allow-author-link="allowAuthorLink"
      :show-date="showDate"
      :show-mana-bars="showManaBars"
      :source="imageURL(articleData.imageURL, { maxDimensions: '750x4000', quality: 75, })"
      :title="articleData.title"
      :on-click="onClick"/>

    <grid-deck-card
      v-if="type === 'deck' && articleData.deckData || type === 'deck' && articleData.deck"
      :author-url="{ path: `${articleData.authorURL}` }"
      :data="articleData"
      :show-vertical-link="showVerticalLink"
      :image="articleData.imageURL"
      :link="{ path: `${articleData.canonicalURL}` }"
      :title="articleData.title"
      :on-click="onClick"
      @click="handleDeckClick"/>

    <card-grid-product
      v-if="type === 'card' && articleData.cardData"
      :article-data="articleData"
      :link="articleData.canonicalURL"
      :show-vertical-link="showVerticalLink"
      :title="articleData.title"
      :on-click="onClick"/>

    <grid-event-card
      v-if="type === 'event'"
      :data="articleData"
      :show-vertical-link="showVerticalLink"
      :min-card-height="minEventCardHeight"
      :on-click="onClick"
      @click="handleEventClick"/>

    <promos-card-grid
      v-if="type === 'promo'"
      :on-click="onClick"
      :content-types="articleData.contentType"
      :promo-reach="articleData.vertical"
      :promo-size="articleData.promoSize"
      :no-promo-available="articleData.noPromoAvailable"/>
  </div>
</template>

<script>
import { inject } from 'vue';
import CardGridProduct from '@/components/card-elements/Cards/Grid/Product.vue';
import imageAlter from '@/mixins/image/alter';
import {
  GridArticleCard,
  GridDeckCard,
  GridEventCard,
  PromosCardGrid
} from '@tcgplayer/martech-components';
import amplitudeEvent from '@tcgplayer/amplitude';

export default {
  name: 'grid-card',
  components: {
    PromosCardGrid,
    GridArticleCard,
    GridDeckCard,
    GridEventCard,
    CardGridProduct,
  },
  mixins: [ imageAlter ],
  props: {
    articleData: {
      type: Object,
      required: true,
      default: null,
    },
    showVerticalLink: {
      type: Boolean,
      required: false,
      default: false,
    },
    showDate: {
      type: Boolean,
      required: false,
      default: true,
    },
    showOverlay: {
      type: Boolean,
      required: false,
      default: true,
    },
    allowAuthorLink: {
      type: Boolean,
      required: false,
      default: true,
    },
    onClick: {
      type: Function,
      required: false,
      default: (article) => { },
    },
    minEventCardHeight: {
      type: String,
      default: '193px',
      required: false,
    },
  },
  setup() {
    const showManaBars = inject('tagLandingCommanderBestiarySeries');

    return {
      showManaBars,
    };
  },
  computed: {
    type() {
      const type = this.articleData?.type
        || this.articleData?.contentType
        || (this?.articleData?.deck && 'deck')
        || 'article';
      return type.toLowerCase();
    },
  },
  methods: {
    handleDeckClick(deck) {
      const data = {
        title: deck.title,
        productLine: deck.vertical,
        format: deck?.deckData?.format || '',
        url: deck.canonicalURL,
        source: 'Grid Card',
        player: deck?.deckData?.ownerName || '',
      };
      amplitudeEvent('infinite', 'infiniteDeckClicked', data);
    },
    handleEventClick(event) {
      const data = {
        title: event.title,
        productLine: event.vertical,
        format: event.format,
        url: event.canonicalURL,
        source: 'Grid Card',
      };
      amplitudeEvent('infinite', 'infiniteEventClicked', data);
    },
  },
};
</script>
